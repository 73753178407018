<template>
  <div>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation class="login-form">
      <slide-y-up-transition appear :delay="500">
        <v-text-field
            :light="!$vuetify.theme.dark"
            label="Email Address"
            prepend-inner-icon="mdi-email"
            type="email"
            v-model="email"
            :rules="emailRule"
            outlined></v-text-field>
      </slide-y-up-transition>
      <slide-y-up-transition appear :delay="600">
        <v-text-field
            :light="!$vuetify.theme.dark"
            label="Password"
            prepend-inner-icon="mdi-lock"
            :append-icon="showPass?'mdi-eye-off':'mdi-eye'"
            class="login-pass"
            @click:append="showPass=!showPass"
            :type="showPass?'text':'password'"
            v-model="password"
            outlined></v-text-field>
      </slide-y-up-transition>
      <slide-y-up-transition appear :delay="700">
        <div class="text-end">
          <v-btn small right text plain :color="$vuetify.theme.dark?'':'black'"
                 class="mt-n3"
                 to="/forgot-password"
                 style="font-weight: 700; text-decoration: none;">
            Forgot Password?
          </v-btn>
        </div>
      </slide-y-up-transition>
      <slide-y-up-transition appear :delay="800">
        <div class="text-center mt-4">
          <v-btn class="px-15"
                 large
                 color="primary"
                 :disabled="email.trim() === '' || password.trim() === ''"
                 :loading="loading"
                 @click="logIn"
          >Log in</v-btn>
        </div>
      </slide-y-up-transition>
      <slide-y-up-transition appear :delay="900">
        <div class="text-center mt-4">
          <v-btn small right text plain :color="$vuetify.theme.dark?'':'black'"
                 style="font-weight: 700; text-decoration: none;"
                 to="/signup"
          >
            Create a new account
          </v-btn>
        </div>
      </slide-y-up-transition>
    </v-form>
    <Dialog :dialog="customDialog"/>
  </div>
</template>

<script>
import { SlideYUpTransition, SlideYDownTransition } from 'vue2-transitions';
import Dialog from "@/components/Dialog";
export default {
  name: "Login",
  components: {
    Dialog,
    SlideYDownTransition,
    SlideYUpTransition
  },
  data(){
    return {
      customDialog: undefined,
      showPass: false,
      email: '',
      emailRule: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      password: '',
      valid: true,
      loading: false
    }
  },
  methods:{
    logIn(){
      if(!this.$refs.form.validate()){
        return;
      }
      this.loading = true;
      fetch(this.$store.state.baseUrl+'auth',{
        method: 'POST',
        headers:{
          'Content-Type':'application/json'
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password
        })
      }).then(res=>{
        return res.json();
      }).then(data=>{
        this.loading = false;
        if(data.status === 200){
          this.$store.commit('setAuth',{
            token: data.token,
            user: data.user,
            permissions: data.permissions
          });
          this.$cookies.set('kbt',data.token);
          this.$store.commit('updateWishlist',data.user.Wishlists.map(w=>{return w.courseId}));
          if(this.$cookies.isKey('wishlist')){
            this.addToWishlist(this.$cookies.get('wishlist'));
          }
          // if(localStorage.getItem('firstLogin') !== 'set'){
          //   this.$store.commit('setShowSplashScreen',true);
          //   setTimeout(()=>{
          //     if(typeof this.$route.query.goto !== 'undefined' && this.$route.query.goto !== ''){
          //       this.$router.push(this.$route.query.goto);
          //     }else{
          //       this.$router.push('/');
          //     }
          //     this.$store.commit('setShowSplashScreen',false);
          //     localStorage.setItem('firstLogin','set');
          //   },4000);
          // }
          // else{
          //   if(typeof this.$route.query.goto !== 'undefined' && this.$route.query.goto !== ''){
          //     return this.$router.push(this.$route.query.goto).then(()=>{
          //
          //     }).catch(err=>{
          //       window.location.reload();
          //     });
          //   }
          //   return this.$router.push('/').then(()=>{
          //
          //   }).catch(err=>{
          //     window.location.reload();
          //   });
          // }

          if(typeof data.store === 'object'){
            for(const key in data.store){
              this.$store.commit('setState',{
                name: key,
                value: data.store[key]
              })
            }
          }
          if(typeof this.$route.query.goto !== 'undefined' && this.$route.query.goto !== ''){
            return this.$router.push(this.$route.query.goto).then(()=>{
            }).catch(err=>{
              return window.location.reload();
            });
          }else if(this.$route.path !== '/login'){
            return window.location.reload();
          }else{
            return this.$router.push('/').then(()=>{}).catch(err=>{
              return window.location.reload();
            });
          }
        }
        this.customDialog = {
          show: true,
          persistent: true,
          text: data.message,
          icon: data.status===200?'success':'error',
          closeBtn: {
            text: data.status===200?'Proceed':'Close',
            color: data.status===200?'primary':'red',
            click: ()=>{
              this.customDialog.show = false;
            }
          }
        };
      }).catch(err=>{
        this.loading = false;
        this.customDialog = {
          show: true,
          persistent: true,
          text: err.message,
          icon: 'error',
          closeBtn: {
            text: 'Close',
            color: 'red',
            click: ()=>{
              this.customDialog.show = false;
            }
          }
        };
      });
    }
  }
}
</script>

<style scoped>

</style>