<template>
  <v-sheet color="transparent" style="height: 100vh; width: 100vw;">
    <v-overlay opacity="0" class="text-center" :class="$vuetify.theme.dark?'gradient-bg-dark':'gradient-bg'">
      <v-progress-linear class="mt-5 rounded-xl" :height="8" style="width: 80px;" indeterminate color="primary"/>
    </v-overlay>
  </v-sheet>
</template>

<script>
export default {
  name: "ContentLoading"
}
</script>

<style scoped>
.loader-div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  margin: 0;
}
</style>