<template>
  <v-card
      max-width="200"
      :height="wishlist?330:300"
      rounded
      class=""
      flat
      outlined
  >
    <v-img
        :src="course.imageUrl"
        height="150"
    >
      <div class="text-right ma-3">
        <v-btn icon :ripple="false" color="primary" @click="toggleWishlist(course.id)">
          <v-icon size="30" :class="{'heart-anim':addAnim}" :ref="'kbIcon'+course.id">{{$store.state.wishlists.find(w=>+w===+course.id)!=null?'mdi-heart':'mdi-heart-outline'}}</v-icon>
        </v-btn>
      </div>
    </v-img>
    <router-link v-ripple class="pa-4 d-flex flex-column" tag="div" :to="'/course/'+course.slug" style="cursor: pointer;">
      <div>
        <h4 class="text-caption short-text" style="font-weight: 600;">
          {{course.name}}
        </h4>
      </div>
      <div v-if="typeof course.User !== 'undefined' && course.User != null" class="mt-n1">
        <h6 class="text-caption short-text grey--text" style="font-weight: 500;">{{toFirstUpper(course.User.firstName)+' '+toFirstUpper(course.User.lastName)}}</h6>
      </div>
      <div v-if="true">
        <div v-if="typeof course.courseReviews === 'object' && course.courseReviews.length > 0" class="text-right mt-n2">
          <v-rating
              x-small
              color="primary"
              size="14"
              readonly
              half-increments
              dense
              :value="calculateRate"></v-rating>
        </div>
        <div class="mt-n2 text-right" v-else>
          <span class="short-text primary--text" style="font-weight: 500; font-size: 10px;">No ratings yet</span>
        </div>
      </div>
      <v-spacer/>
      <v-row class="mt-auto">
        <v-col cols="12" class="text-right short-text pt-0 pb-1">
          <div class="short-text d-flex flex-row-reverse align-end">
            <div v-if="course.allowIndividualEnrol">
              <v-chip color="primary" x-small>Individual</v-chip>
              <div v-if="course.allowDiscount">
                <div
                    class="text-caption ml-1 primary--text pb-n2 mb-n2"
                    style="font-weight: 700;"
                >
                  {{formatPrice(course.discountPrice)}}
                </div>
                <del
                    class="text-caption grey--text"
                    style="font-weight: 500;"
                >
                  {{formatPrice(course.price)}}
                </del>
              </div>
              <div
                  v-else
                  class="text-caption ml-1 primary--text"
                  style="font-weight: 700;"
              >
                {{formatPrice(course.price)}}
              </div>
            </div>
            <v-divider v-if="course.allowIndividualEnrol && course.allowGroupEnrol" class="mx-3" vertical inset></v-divider>
            <div v-if="course.allowGroupEnrol">
              <v-chip color="orange" x-small>Group</v-chip>
              <div v-if="course.allowGroupDiscount">
                <div
                    class="text-caption ml-1 primary--text pb-n2 mb-n2"
                    style="font-weight: 700;"
                >
                  {{formatPrice(course.groupDiscountPrice)}}
                </div>
                <del
                    class="text-caption grey--text"
                    style="font-weight: 500;"
                >
                  {{formatPrice(course.groupPrice)}}
                </del>
              </div>
              <div
                  v-else
                  class="text-caption ml-1 primary--text"
                  style="font-weight: 700;"
              >
                {{formatPrice(course.groupPrice)}}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </router-link>
    <div v-if="wishlist" class="text-center px-3 pb-3 mt-n1">
      <v-btn style="width: 100%;" outlined small color="primary" @click="$emit('enrolClicked')">{{enrolBtnText}}</v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CourseCard",
  props:{
    course:{
      type: Object,
      required: true
    },
    wishlist:{
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data: ()=>({
    addAnim: false
  }),
  methods:{
    toggleWishlist(id){
      this.addAnim = true;
      setTimeout(()=>{
        this.addAnim = false;
      },1000);
      let existing = this.$store.state.wishlists;
      if(existing.find(w=>+w===+id) == null){
        this.addToWishlist(id);
        this.$emit('addedToWishlist');
      }else{
        this.removeFromWishlist(id);
        this.$emit('removedFromWishlist');
      }
    }
  },
  computed:{
    calculateRate(){
      if(this.course==null){
        return 0;
      }
      if(typeof this.course.courseReviews !== "object"){
        return 0;
      }
      if(this.course.courseReviews.length <= 0){
        return 0;
      }
      let rate = 0;
      this.course.courseReviews.forEach(r=>{
        rate += r.rate;
      });
      return parseFloat((rate/(this.course.courseReviews.length)).toFixed(1));
    },
    enrolBtnText(){
      if(typeof this.course.passed === 'boolean' && !this.course.passed){
        return 'Take quiz to enrol'
      }
      return 'Enrol'
    }
  }
}
</script>

<style scoped>

</style>