require('es6-promise').polyfill()

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VueMeta from 'vue-meta'
import VueSession from "vue-session"
import VueClipboard from "vue-clipboard2"
import VueCookies from 'vue-cookies'
import VueDndZone from 'vue-dnd-zone'

import Flicking from '@egjs/vue-flicking'
import moment from 'moment-timezone'
import VueViewer from 'v-viewer'

import '@egjs/vue-flicking/dist/flicking.css'
import 'vue-dnd-zone/vue-dnd-zone.css'
import 'viewerjs/dist/viewer.css'

import mixins from './mixins'

require('vue2-animate/dist/vue2-animate.min.css')
require('./assets/css/style.css')

Vue.prototype.$moment = moment
Vue.use(VueClipboard)
Vue.use(VueSession,{
  persist: true
})
Vue.use(VueMeta,{
  refreshOnceOnNavigation: true
})
Vue.use(Flicking)
Vue.use(VueCookies, { expires: '30d'})
Vue.use(VueDndZone);
Vue.use(VueViewer);
VueViewer.setDefaults({
  title: false,
})
Vue.mixin(mixins);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
