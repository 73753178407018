<template>
  <div>
    <div class="d-flex mt-2">
      <div class="d-inline">
        <h4 class="text-subtitle-1 short-text" style="font-weight: 700;">
          {{ title }}
        </h4>
      </div>
      <div v-if="showTitleBtn" class="d-inline ml-auto">
        <v-btn
            plain
            text
            :class="$vuetify.theme.dark?'primary--text':'black--text'"
            small
            style="font-weight: 600;"
            :to="btnLink != null?btnLink:undefined"
        >
          {{btnText}}
        </v-btn>
      </div>
    </div>
    <Flicking
        class=""
        :options="{align: 'prev', circular: false}"
    >
      <div class="panel py-4 px-2" v-for="(course, j) in items"
           :key="j">
        <CourseCard :course="course"/>
      </div>
    </Flicking>
  </div>
</template>

<script>
import CourseCard from "@/components/CourseCard";
export default {
  name: "SlideCard",
  components: {CourseCard},
  props:{
    title:{
      type: String,
      required: true
    },
    items:{
      type: Array,
      required: true,
      default(){
        return [];
      }
    },
    showTitleBtn: {
      type: Boolean,
      default() {
        return false;
      }
    },
    btnText: {
      type: String,
      default(){
        return null;
      }
    },
    btnLink: {
      type: String,
      default(){
        return null;
      }
    },
    btnIcon: {
      type: String,
      default(){
        return 'mdi-arrow-right-thick';
      }
    }
  },
  methods:{

  }
}
</script>

<style scoped>

</style>