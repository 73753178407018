<template>
  <v-bottom-navigation
    grow
    fixed
    :color="$vuetify.theme.dark?'#121212':'#F0FBFAFF'"
    class="d-none"
  >
    <v-btn>
      <v-icon>mdi-home</v-icon>
    </v-btn>
    <v-btn>
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    <v-btn>
      <v-icon>mdi-account</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>