<template>
  <vue-sticky-sidebar v-if="$store.state.contentLoaded" style="" class="sidebar ml-8" :topSpacing="100" containerSelector=".stick-container" innerWrapperSelector=".sidebar__inner">
    <v-card flat class="rounded-xl sidebar__inner pa-5">
      <div v-if="isLoggedIn">
        <Profile/>
        <v-card flat color="transparent">
          <v-card-text class="pa-0 ma-0">
            <div class="mt-auto">
              <v-list
                  dense rounded color="transparent"
              >
                <template
                    v-for="item in $store.state.accessMenu">
                  <div
                      v-if="menuShow(item)"
                      :key="item.id"
                  >
                    <div
                        v-if="item.children"
                    >
                      <v-list-group
                          no-action
                          :ripple="false"
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.title" style="font-weight: 600;"></v-list-item-title>
                          </v-list-item-content>
                        </template>
                        <template v-for="child in item.children">
                          <div v-if="menuShow(child)"
                               :key="child.id">
                            <div v-if="child.children">
                              <v-list-group
                                  no-action
                                  sub-group
                                  :ripple="false"
                              >
                                <template v-slot:activator>
                                  <v-list-item-content>
                                    <v-list-item-title v-text="child.title"></v-list-item-title>
                                  </v-list-item-content>
                                </template>

                                <template
                                    v-for="sub in child.children">
                                  <v-list-item
                                      :key="sub.id"
                                      v-if="menuShow(sub)"
                                      link
                                      :to="sub.to||undefined"
                                      :href="sub.link||undefined"
                                      :target="sub.link?'_blank':undefined"
                                      :ripple="false"
                                      @click="clickItem(sub.click)"
                                      color="primary"
                                  >
                                    <v-list-item-title v-text="sub.title" style="font-weight: 600;"></v-list-item-title>
                                  </v-list-item>
                                </template>
                              </v-list-group>
                            </div>
                            <div v-else>
                              <v-list-item
                                  link
                                  class="pl-7"
                                  :to="child.to||undefined"
                                  :href="child.link||undefined"
                                  :target="child.link?'_blank':undefined"
                                  :ripple="false"
                                  @click="clickItem(child.click)"
                              >
                                <v-list-item-content>
                                  <v-list-item-title v-text="child.title" style="font-weight: 600;"></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </div>
                          </div>
                        </template>
                      </v-list-group>
                    </div>
                    <div
                        v-else
                    >
                      <v-list-item
                          link
                          :to="item.to||undefined"
                          :href="item.link||undefined"
                          :target="item.link?'_blank':undefined"
                          :ripple="false"
                          color="primary"
                          @click="clickItem(item.click)"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="item.title" style="font-weight: 600;"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </div>
                </template>

              </v-list>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div v-else>
        <div class="mt-3">
          <div
              v-if="$store.state.loadingAuth">
            <v-skeleton-loader
                type="list-item-avatar-three-line, image, article"
            ></v-skeleton-loader>

          </div>
          <div v-else>
            <slide-y-up-transition appear :delay="300">
              <h4 class="text-h6" style="font-weight: 700;">Login</h4>
            </slide-y-up-transition>
            <slide-y-up-transition appear :delay="400">
              <h6 class="text-caption" style="font-weight: 600;">Enter your email and password to continue</h6>
            </slide-y-up-transition>
            <Login class="mt-3"/>
          </div>
        </div>
      </div>
    </v-card>
    <Dialog :dialog="customDialog"/>
  </vue-sticky-sidebar>
</template>

<script>
import vueStickySidebar from 'vue-sticky-sidebar';
import Profile from "@/components/Profile";
import Login from "@/components/Login";
import { SlideYUpTransition, SlideYDownTransition } from 'vue2-transitions';
import Dialog from "@/components/Dialog";
export default {
  name: "RightSidebar",
  components: {
    Dialog,
    Login,
    Profile,
    vueStickySidebar,
    SlideYUpTransition
  },
  data: ()=>({
    customDialog: undefined
  })
}
</script>

<style scoped>

</style>