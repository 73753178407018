<template>
  <v-app >
      <div v-if="isLoggedIn && $store.state.user.Interests.length < 3">
        <InterestScreen/>
      </div>
      <div v-else-if="$store.state.showSplashScreen">
        <SplashScreen/>
      </div>
      <div v-else>
        <v-main :class="$vuetify.theme.dark?'gradient-bg-dark':'gradient-bg'">
          <Header/>
          <v-card flat color="transparent">
            <v-row class="ma-0 pa-0" :class="$vuetify.theme.dark?'gradient-bg-dark':'gradient-bg'">
              <v-col cols="3" class="hidden-sm-and-down pa-0 stick-container" v-if="!disabledPages.includes($route.path)&&!$store.state.disableRightSideBar">
                <RightSidebar/>
              </v-col>
              <v-col class="pa-0" cols="12" :md="disabledPages.includes($route.path)||$store.state.disableRightSideBar?12:9">
                  <SlideYDownTransition :key="$route.fullPath" appear mode="out-in">
                    <router-view :key="$route.fullPath"></router-view>
                  </SlideYDownTransition>
              </v-col>
            </v-row>
          </v-card>
          <Footer/>
        </v-main>
        <v-snackbar v-for="bar in notificationSnackBars.filter(n=>n.show)" :key="bar.id"
                    v-model="notificationSnackBars.find(s=>s.id===bar.id).show" 
                    multi-line color="primary" right bottom>
          {{bar.title}}
          <template v-slot:action="{ attrs }">
            <div class="d-inline">
              <div class="d-inline" v-if="bar!=null&&bar.trim()!==''">
                <v-btn
                    v-for="(btn,j) in JSON.parse(bar)"
                    :key="j"
                    color="white"
                    text
                    v-bind="attrs"
                    @click="clickItem(btn.click)"
                >
                  btn.text
                </v-btn>
              </div>
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="notificationSnackBars.find(s=>s.id===bar.id).show = false"
              >
                Close
              </v-btn>
            </div>
          </template>
        </v-snackbar>
      </div>
  </v-app>
</template>

<script>

import SplashScreen from "@/views/Screen/SplashScreen";
import Header from '@/components/Shared/Header'
import Footer from '@/components/Shared/Footer'
import { SlideXLeftTransition, SlideYDownTransition } from "vue2-transitions";
import ContentLoading from "@/components/ContentLoading";
import RightSidebar from "@/components/RightSidebar";
import InterestScreen from "@/views/Screen/User/InterestScreen";
import update from './mixins/update'
export default {
  name: 'App',
  metaInfo(){
    return {
      title: 'Explore',
      titleTemplate: `%s | ${this.$store.state.siteName}`,
      link: [
        {
          rel: 'shortcut icon',
          href: '/img/kobbware-icon.png'
        },
      ],
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    }
  },
  mixins: [update],
  components: {
    InterestScreen,
    RightSidebar,
    ContentLoading,
    SplashScreen,
    SlideYDownTransition,
    Header,
    Footer,
  },
  destroyed(){
    try{
      clearInterval(this.refreshInterval);
      clearInterval(this.autoInterval);
    }catch(e){

    }
  },
  created(){
    if(typeof localStorage.getItem('theme') === 'undefined' || localStorage.getItem('theme') == null){
      const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      if (darkMediaQuery.matches) {
        this.$vuetify.theme.dark = true;
      }
      try {
        // Chrome & Firefox
        darkMediaQuery.addEventListener('change', (e) => {
          this.$vuetify.theme.dark = e.matches;
        });
      } catch (e1) {
        try {
          // Safari
          darkMediaQuery.addListener((e) => {
            this.$vuetify.theme.dark = e.matches;
          });
        } catch (e2) {
          console.error(e2);
        }
      }
    }
    else{
      this.$vuetify.theme.dark = localStorage.getItem('theme') === 'dark';
    }
    if(this.$cookies.isKey('kbt')){
      this.$store.commit('setAuth',{
        token: this.$cookies.get('kbt'),
        user: {},
        permissions: []
      });
    }
    if(this.$store.state.jwt != null && this.$store.state.jwt.trim() !== ''){
      this.fetchAuth().then(data=>{
        //console.log(data);
        if(data.status===200){
          // this.autoInterval = setInterval(()=>{
          //   this.getAuto();
          // },10000);
        }
      }).catch(err=>{

      });
    }else{
      if(this.$cookies.isKey('wishlist')){
        this.addToWishlist(this.$cookies.get('wishlist'));
      }
    }
    this.changeEditorTheme();
    // this.refreshInterval = setInterval(()=>{
    //   if(this.isLoggedIn()){
    //     this.loadContent('auth').then(data=>{
    //       if(this.isLoggedIn()){
    //         if (data.status === 200) {
    //           this.$store.commit('setAuth', {
    //             token: this.$cookies.get('kbt'),
    //             user: data.user,
    //             permissions: data.permissions
    //           });
    //           // if(this.$cookies.isKey('wishlist')){
    //           //   this.addToWishlist(this.$cookies.get('wishlist'));
    //           // }
    //         }
    //         else if(data.status === 401 && typeof data.message !== 'undefined') {
    //           this.$store.commit('setAuth', {
    //             token: '',
    //             user: {},
    //             permissions: []
    //           });
    //           this.$cookies.set('kbt','');
    //           this.$store.commit('updateWishlist',[]);
    //         }
    //       }
    //     }).catch(err=>{
    //
    //     });
    //   }
    // },30000);
  },
  watch:{
    '$vuetify.theme.dark'(val) {
      let metaThemeColor = document.querySelector("meta[name=theme-color]");
      if (val) {
        metaThemeColor.setAttribute("content", '#121212');
      } else {
        metaThemeColor.setAttribute("content", '#F0FBFAFF');
      }
      this.changeEditorTheme();
    }
  },
  mounted() {


  },
  data: () => ({
    disabledPages: [
        '/login',
        '/forgot-password',
        '/signup',
        '/reset-password',
        '/confirm-code',
    ],
    refreshInterval: null,
    notificationSnackBars: [],
    autoInterval: null
  }),
  methods:{
    clickItem(click){
      if(typeof click !== 'undefined'){
        try{
          eval(click);
        }catch(e){

        }
      }
    },
    changeEditorTheme(){
      let bodyStyles = document.body.style;
      bodyStyles.setProperty('--ck-color-base-foreground', this.$vuetify.theme.dark?'#1E1E1E':'white');
      bodyStyles.setProperty('--ck-color-base-background', this.$vuetify.theme.dark?'#1E1E1E':'white');
      bodyStyles.setProperty('--ck-color-base-text', this.$vuetify.theme.dark?'white':'black');
    },
    getAuto(){
      this.loadContent('auto').then(data=>{
        if(data.status===200){
          if(data.notifications.length>0){
            if(data.notifications.length>1){
              this.notificationSnackBars.push({
                title: `You have ${data.notifications.length} new notifications`,
                buttons: [
                  {
                    text: 'View all',
                    click: `this.$router.push('/profile/notifications')`
                  }
                ],
                show: true
              })
            }else{
              this.notificationSnackBars.push({
                title: data.notifications[0].title,
                buttons: data.notifications[0].buttons,
                show: true
              })
            }
          }
          this.$store.commit('setState',{
            name: 'sessions',
            value: data.sessions
          })
        }
        if(data.status===401){
          clearInterval(this.autoInterval);
          return this.$router.push('/login?goto='+this.formatPath());
        }
      })
    },
  }
};
</script>
<style>

</style>