<template>
  <div>
    <v-card flat color="transparent">
      <v-card-text class="pa-0 ma-0">
        <div class="text-center">
          <v-avatar color="primary lighten-1" size="100">
              <span v-if="$store.state.user.imageUrl == null || $store.state.user.imageUrl.trim() === ''" class="white--text text-h4 font-weight-bold">
                {{toFirstUpper($store.state.user.firstName.split('')[0])+toFirstUpper($store.state.user.lastName.split('')[0])}}
              </span>
            <v-img v-if="$store.state.user.imageUrl != null && $store.state.user.imageUrl.trim() !== ''" :src="$store.state.user.imageUrl"/>
            <div style="width: 100%; height: 100%; position: absolute;" class="d-flex justify-center">
              <v-btn icon plain :ripple="false" class="align-self-center" :loading="imageLoading" @click="setUpImageUploader">
                <v-icon size="30">mdi-camera</v-icon>
              </v-btn>
            </div>
          </v-avatar>
          <h4 class="text-subtitle-2 font-weight-bold mt-2">{{toFirstUpper($store.state.user.firstName)+' '+toFirstUpper($store.state.user.lastName)}}</h4>
          <h6 class="text-caption font-weight-medium primary--text mt-n1 mb-1">{{toFirstUpper($store.state.user.Role.name)}}</h6>
          <div class="mt-2">
            <v-btn fab class="mr-3" outlined color="primary" small to="/profile">
              <v-icon>mdi-account</v-icon>
            </v-btn>
            <v-btn v-if="$store.state.user.Wishlists.length <= 0 && $store.state.user.id === $store.state.user.id" fab class="mr-3" outlined color="primary" small to="/wishlist">
              <v-icon>mdi-heart</v-icon>
            </v-btn>
            <v-badge
                bordered
                color="primary"
                overlap
                v-if="$store.state.user.Wishlists.length > 0 && $store.state.user.id === $store.state.user.id"
                :content="$store.state.user.Wishlists.length"
                offset-x="20" class="mr-3"
            >
              <v-btn fab outlined color="primary" small to="/wishlist">
                <v-icon>mdi-heart</v-icon>
              </v-btn>
            </v-badge>
            <v-btn v-if="$store.state.user.id === $store.state.user.id" fab outlined color="red" small @click="confirmLogout">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </div>
        </div>
        <div v-if="detailed&&typeof $store.state.user.Session !== 'undefined'">
          <div class="text-center">
            <v-divider class="my-5 mx-8"/>
          </div>
          <v-expansion-panels popout flat>
            <v-expansion-panel style="background-color: transparent;" v-if="$store.state.user.Session.requested.length > 0">
              <v-expansion-panel-header>
                Requested Sessions
              </v-expansion-panel-header>
              <v-expansion-panel-content style="background-color: transparent;">
                <v-card v-for="request in $store.state.user.Session.requested" :key="request.id" class="pa-0 mb-3" style="border-radius: 10px;">
                  <v-card-text class="short-text">
                    <div class="text-subtitle-2 font-weight-bold">{{request.enrolledLesson.enrolledModule.enrolledCourse.name}}</div>
                    <div class="text-subtitle-2 font-weight-medium">{{request.enrolledLesson.title}}</div>
                    <div class="text-caption">Schedule: {{formatDate(request.schedule)}}</div>
                    <div class="text-caption">Price: {{formatPrice(request.amount)}}</div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" small :to="'/enrolment/'+request.enrolledLesson.enrolledModule.enrolledCourse.Enrolment.slug+'?requested-sessions'">
                      Go to class
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="background-color: transparent;" v-if="$store.state.user.Session.ongoing.length > 0">
              <v-expansion-panel-header>
                Ongoing Sessions
              </v-expansion-panel-header>
              <v-expansion-panel-content style="background-color: transparent;">
                <v-card v-for="session in $store.state.user.Session.ongoing" :key="session.id" class="pa-0 mb-3" style="border-radius: 10px;">
                  <v-card-text class="short-text">
                    <div class="text-subtitle-2 font-weight-bold">{{session.enrolledLesson.enrolledModule.enrolledCourse.name}}</div>
                    <div class="text-subtitle-2 font-weight-medium">{{session.enrolledLesson.title}}</div>
                    <div class="text-caption">Started: {{formatChatTime(session.starts)}}</div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" small :to="'/enrolment/'+session.enrolledLesson.enrolledModule.enrolledCourse.Enrolment.slug+'?ongoing-sessions'">
                      Go to class
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="background-color: transparent;" v-if="$store.state.user.Session.upcoming.length > 0">
              <v-expansion-panel-header>
                Upcoming Sessions
              </v-expansion-panel-header>
              <v-expansion-panel-content style="background-color: transparent;">
                <v-card v-for="session in $store.state.user.Session.upcoming" :key="session.id" class="pa-0 mb-3" style="border-radius: 10px;">
                  <v-card-text class="short-text">
                    <div class="text-subtitle-2 font-weight-bold">{{session.enrolledLesson.enrolledModule.enrolledCourse.name}}</div>
                    <div class="text-subtitle-2 font-weight-medium">{{session.enrolledLesson.title}}</div>
                    <div class="text-caption">Schedule: {{formatDate(session.schedule)}}</div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" small :to="'/enrolment/'+session.enrolledLesson.enrolledModule.enrolledCourse.Enrolment.slug+'?upcoming-sessions'">
                      Go to class
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card-text>
    </v-card>
    <Dialog :dialog="customDialog"/>
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
export default {
  name: "Profile",
  components: {
    Dialog

  },
  props: {
    detailed: {
      type: Boolean,
      default(){
        return true;
      }
    },
  },
  data: ()=>({
    customDialog: undefined,
    imageLoading: false,
  }),
  methods: {
    setUpImageUploader(){
      let image;
      let valid = ['gif','jpeg','jpg','png'];
      let input = document.createElement('input');
      input.type = 'file';
      input.accept = "image/jpeg,image/jpg,image/png,image/gif";
      input.onchange = _ => {
        if(typeof input.files !== 'object' || input.files.length <= 0){
          return;
        }
        image = input.files[0];
        let name = image.name;
        let ext = name.split('.')[name.split('.').length - 1];
        if(!valid.includes(ext)){
          this.customDialog = {
            show: true,
            persistent: false,
            text: 'Please upload a valid extension type',
            icon: 'error',
            buttons: [
              {
                text: 'Close',
                color: 'red',
                click: ()=>{
                  this.customDialog.show = false;
                }
              },
            ]
          };
          return;
        }
        if(image.size/(1024*1024) > 1){
          this.customDialog = {
            show: true,
            persistent: false,
            text: 'The image size is larger than 1mb. You might want to consider compressing it before upload.',
            icon: 'error',
            buttons: [
              {
                text: 'Close',
                color: 'red',
                click: ()=>{
                  this.customDialog.show = false;
                }
              },
            ]
          };
          return;
        }
        let newImage;
        let newName = Date.now()+''+Math.round(Math.random()*1E9)+'.'+ext;
        let newOption = {
          type: image.type
        }
        try {
          newImage = new File([image], newName, newOption);
        } catch (e) {
          newImage = image;
        }
        let formData = new FormData();
        formData.append('avatar',newImage);
        this.uploadAvatar(formData);
      };
      input.click();
    },
    uploadAvatar(formData){
      this.imageLoading = true;
      fetch(this.$store.state.baseUrl+'user/avatar',{
        method: 'PUT',
        headers: {
          'Authorization': this.$store.state.jwt
        },
        body: formData
      }).then(res=>{
        return res.json();
      }).then(data=>{
        this.imageLoading = false;
        if(data.status === 201){
          this.$store.commit('updateAvatar',data.avatar)
          return;
        }
        if(data.status === 401){
          return this.$router.push('/login?goto='+this.formatPath());
        }
        this.customDialog = {
          show: true,
          persistent: false,
          text: data.message,
          icon: 'error',
          buttons: [
            {
              text: 'Close',
              color: 'red',
              click: ()=>{
                this.customDialog.show = false;
              }
            },
            {
              text: 'Retry',
              color: 'orange',
              click: ()=>{
                this.customDialog.show = false;
                this.uploadAvatar(formData);
              }
            },
          ]
        };
      }).catch(err=>{
        this.imageLoading = false;
        this.customDialog = {
          show: true,
          persistent: false,
          text: err.message,
          icon: 'error',
          buttons: [
            {
              text: 'Close',
              color: 'red',
              click: ()=>{
                this.customDialog.show = false;
              }
            },
            {
              text: 'Retry',
              color: 'orange',
              click: ()=>{
                this.customDialog.show = false;
                this.uploadAvatar(formData);
              }
            },
          ]
        };
      });
    },
  },
}
</script>

<style scoped>

</style>