<template>
  <div>
    <div v-if="!$store.state.contentLoaded">
      <ContentLoading/>
    </div>
    <div v-else>
      <v-app>
        <v-main class="pt-2 pb-16">
          <v-container fluid>
            <v-row>
              <v-col class="mt-0 pt-0 pr-md-10" cols="12">
                <v-card
                    flat
                    color="transparent">
                  <v-card-title v-if="typeof $store.state.user.firstName !== 'undefined'">
                    <h4 class="text-h5 text-h4 short-text" style="font-weight: 800;">Hello {{toFirstUpper($store.state.user.firstName)+' '+toFirstUpper($store.state.user.lastName)}},</h4>
                  </v-card-title>
                  <v-card-subtitle>
                    <h6 class="text-subtitle-2 text-h6">Find a course you want to learn</h6>
                  </v-card-subtitle>
                </v-card>
                <v-card
                    flat
                    color="transparent"
                >
                  <div class="px-3">
                    <div style="width: 100%">
                      <v-text-field
                          :color="$vuetify.theme.dark?'white':'black'"
                          :light="!$vuetify.theme.dark"
                          label="Search for a course"
                          prepend-inner-icon="mdi-magnify"
                          type="text"
                          solo
                          clearable
                          height="60"
                          @keyup.enter="$router.push({path:'/search',query:{q:searchInput}})"
                          ref="kbSearch"
                          v-model="searchInput"
                          :loading="searchLoading"
                      ></v-text-field>
                      <div class="mt-n7" style="position: relative; width: 100%" v-if="searchInput != null && searchInput.trim() !== ''">
                        <v-card style="position: absolute; top: 0; z-index: 2; width: 100%;"
                                :disabled="searchLoading">
                          <SearchResult :items="searchResult" :max-height="400" :query="searchInput"></SearchResult>
                        </v-card>
                      </div>
                    </div>
                    <div>
                      <v-row>
                        <v-col cols="7" md="9">
                          <h4 class="text-h6 text-md-h5" style="font-weight: 700;">
                            Categories
                          </h4>
                        </v-col>
                      </v-row>
                      <Flicking
                          class="mt-5"
                          :options="{align: 'prev', circular: false}"
                      >
                        <div class="panel" v-for="category in allCategories"
                             :key="category.id">
                          <v-chip
                              color="primary"
                              class="pa-5 mr-4 text-subtitle-2 mb-4"
                              small
                              style="font-weight: 700; max-width: 150px;"
                              :to="'/category/'+category.slug"
                          >
                            <span class="short-text">{{category.name}}</span>
                          </v-chip>
                        </div>
                      </Flicking>
                    </div>
                    <SlideCard v-for="(item, i) in categories" :key="item.id" :title="item.name" :items="item.courses" show-title-btn btn-text="See all" :btn-link="item.slug"/>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-app>
    </div>
    <Dialog :dialog="customDialog"/>
  </div>
</template>

<script>
import SearchResult from "@/components/SearchResult";
import SlideCard from "@/components/SlideCard";
import store from '@/store'
import ContentLoading from "@/components/ContentLoading";
import Dialog from "@/components/Dialog";
export default {
  name: "HomeScreen",
  metaInfo(){
    return {
      title: 'Explore'
    }
  },
  created(){
    this.debouncedSearch = this.debounce(()=>{
      this.execSearch();
    }, 1000);
    this.fetchContent();
  },
  mounted() {
  },
  components: {
    Dialog,
    ContentLoading,
    SlideCard,
    SearchResult
  },
  watch:{
    searchInput(val){
      this.debouncedSearch();
    },
  },
  methods:{
    execSearch(){
      if(this.searchInput == null || this.searchInput.trim().length < 3){
        this.searchResult = [];
        return;
      }
      this.searchLoading = true;
      this.loadContent('courses?query='+this.searchInput.trim()).then(data=>{
        this.searchLoading = false;
        if(data.status === 200){
          this.searchResult = data.courses.slice(0,10);
          if(this.searchResult.length <= 0){
            this.searchResult = [{
              no_result: true
            }];
          }
          if(data.courses.length > 10){
            this.searchResult.push({
              view_all: true
            })
          }
        }else{
          this.customDialog = {
            show: true,
            persistent: true,
            text: data.message,
            icon: 'error',
            buttons: [
              {
                text: 'Retry',
                color: 'orange',
                click: ()=>{
                  this.customDialog.show = false;
                  this.execSearch();
                }
              },
              {
                text: 'Close',
                color: 'red',
                click: ()=>{
                  this.customDialog.show = false;
                }
              }
            ]
          };
        }
      }).catch(err=>{
        this.searchLoading = false;
        this.customDialog = {
          show: true,
          persistent: true,
          text: err.message,
          icon: 'error',
          buttons: [
            {
              text: 'Retry',
              color: 'orange',
              click: ()=>{
                this.customDialog.show = false;
                this.execSearch();
              }
            },
            {
              text: 'Close',
              color: 'red',
              click: ()=>{
                this.customDialog.show = false;
              }
            }
          ]
        };
      });
    },
    fetchContent(){
      this.$store.commit('setContentLoaded',false);
      this.loadContent('home').then(data=>{
        if(data.status === 200){
          this.$store.commit('setContentLoaded',true);
          this.categories = data.categories;
          this.allCategories = data.all_categories;
        }else{
          this.customDialog = {
            show: true,
            persistent: true,
            text: data.message,
            icon: 'error',
            buttons: [
              {
                text: 'Retry',
                color: 'orange',
                click: ()=>{
                  this.customDialog.show = false;
                  this.fetchContent();
                }
              },
              {
                text: 'Close',
                color: 'red',
                click: ()=>{
                  this.customDialog.show = false;
                }
              }
            ]
          };
        }
      }).catch(err=>{
        this.customDialog = {
          show: true,
          persistent: true,
          text: err.message,
          icon: 'error',
          buttons: [
            {
              text: 'Retry',
              color: 'orange',
              click: ()=>{
                this.customDialog.show = false;
                this.fetchContent();
              }
            },
            {
              text: 'Close',
              color: 'red',
              click: ()=>{
                this.customDialog.show = false;
              }
            }
          ]
        };
      });
    }
  },
  data: ()=>({
    searchInput: '',
    searchResult: [],
    searchLoading: false,
    categories:[],
    allCategories: [],
    customDialog: undefined
  })
}
</script>

<style scoped>

</style>