<template>
  <div
      v-if="items.length > 0"
  >
    <v-virtual-scroll
        :items="items"
        :item-height="80"
        :max-height="maxHeight"
        :min-height="120"
        :bench="items.length"
        v-if="type === 'scroll'"
    >
      <template v-slot:default="{item, index}">
        <div
            class="pa-3">
          <div v-if="typeof item.name !== 'undefined'">
            <v-list-item ripple :to="'/course/'+item.slug">
              <v-list-item-avatar>
                <v-img :src="item.imageUrl"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <h4 class="text-subtitle-1 short-text" style="font-weight: 600; ">{{item.name}}</h4>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <h6 class="text-caption short-text grey--text" style="font-weight: 500;">{{item.creator}}</h6>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>
                                <span
                                    class="text-h6 ml-1 primary--text"
                                    style="font-weight: 700;"
                                >
                                {{item.discountPrice > 0?formatPrice(item.discountPrice):formatPrice(item.price)}}
                              </span>
                </v-list-item-action-text>
                <div v-if="item.rating > 0">
                  <v-rating
                      color="primary"
                      size="14"
                      readonly
                      half-increments
                      dense
                      :value="item.rating"></v-rating>
                </div>
                <div v-else>
                  <span class="text-caption short-text primary--text" style="font-weight: 500;">No ratings yet</span>
                </div>
              </v-list-item-action>
            </v-list-item>
            <v-divider inset v-if="index < (items.length - 1)"></v-divider>
          </div>
          <div v-else-if="typeof item.no_result !== 'undefined'">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-center">
                  <h6 class="text-subtitle-1 text-md-h6" style="font-weight: 600;">No course found</h6>
                  <h6 class="text-subtitle-2 text-md-subtitle-2" style="font-weight: 500;">Please try another keyword</h6>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div v-else-if="typeof item.view_all !== 'undefined'">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-center">
                  <v-btn
                      text
                      plain
                      color="primary"
                      block
                      :to="{path:'/search',query:{q:formatQuery}}"
                  >
                    <h6 class="text-subtitle-1 text-md-h6" style="font-weight: 600;">View All</h6>
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </template>
    </v-virtual-scroll>
    <v-container v-if="type === 'card'" fluid>
      <v-row>
        <v-col 
            v-for="(item, i) in items" :key="i" 
            :cols="typeof item.name !== 'undefined'?'6':'12'"
            :md="typeof item.name !== 'undefined'?'4':'12'"
            class="px-2 mx-0"
        >
          <div v-if="typeof item.name !== 'undefined'">
            <CourseCard :course="item"/>
          </div>
          <div v-else-if="typeof item.no_result !== 'undefined'" style="height: 300px;">
            <v-overlay class="text-center" absolute opacity="0" :dark="$vuetify.theme.dark">
              <h6 class="text-subtitle-1 text-md-h6" style="font-weight: 600;">No course found</h6>
              <h6 class="text-subtitle-2 text-md-subtitle-2" style="font-weight: 500;">Please try another keyword</h6>
            </v-overlay>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CourseCard from "@/components/CourseCard";
export default {
  name: "SearchResult",
  components: {CourseCard},
  props: {
    items: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    maxHeight: {
      type: [String,Number],
      default: undefined
    },
    type: {
      type: String,
      default: ()=>{
        return 'scroll'
      }
    },
    query: {
      type: String,
      default: ()=>{
        return ''
      }
    }
  },
  computed:{
    formatQuery(){
      return this.textToSlug(this.query);
    }
  }
}
</script>

<style scoped>

</style>