<template>
  <div>
    <v-app-bar
        app
        :light="!$vuetify.theme.dark"
        elevate-on-scroll
        :color="$vuetify.theme.dark?'#121212':'#F0FBFAFF'"
        :height="80"
        class="pr-6 pl-4"
        style="z-index: 1001;"
    >
      <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          icon
          :ripple="false"
          plain
          text
          x-large
          @click="drawer = !drawer"
      >
        <v-icon v-if="drawer">mdi-arrow-left</v-icon>
        <v-icon v-else>mdi-menu</v-icon>
      </v-btn>
      <div class="d-flex align-center mx-3">
        <router-link to="/">
          <v-img
              alt="Kobbware Logo"
              class="shrink"
              contain
              :src="$vuetify.breakpoint.smAndDown?'/img/kobbware-icon.png':($vuetify.theme.dark?'/img/kobbware-logo-white.png':'/img/kobbware-logo.png')"
              transition="scale-transition"
              :width="$vuetify.breakpoint.smAndDown?20:150"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <div v-if="$vuetify.breakpoint.mdAndUp" class="mr-5">
        <v-menu
            open-on-hover
            bottom
            offset-y
            transition="slide-y-transition"
            v-for="item in $store.state[isLoggedIn?'userHeader':'guestHeader']"
            :key="item.id"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                plain
                v-bind="attrs"
                v-on="on"
                :to="item.to||undefined"
                :href="item.link||undefined"
                :target="item.link?'_blank':undefined"
                @click="performClick(item.click)"
            >
              <span class="mr-1" v-text="item.title"></span>
              <v-icon v-if="item.children" class="ml-n2">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <div v-if="item.children">
            <v-list
                :color="$vuetify.theme.dark?'#121212':'#F0FBFAFF'"
            >
              <v-list-item
                  v-for="child in item.children"
                  :key="child.id"
                  link
                  :to="child.to||undefined"
                  :href="child.link||undefined"
                  :target="child.link?'_blank':undefined"
                  @click="performClick(child.click)"
              >
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>
      </div>
      <v-btn
          icon
          plain
          text
          :ripple="false"
          to="/search"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn
          icon
          plain
          text
          @click="changeTheme"
          :ripple="false"
      >
        <v-icon>{{$vuetify.theme.dark?'mdi-lightbulb-on':'mdi-lightbulb'}}</v-icon>
      </v-btn>
      <div v-if="$vuetify.breakpoint.smAndDown&&isLoggedIn">
        <v-btn
            icon
            plain
            text
            :ripple="false"
            @click="rightDrawer = !rightDrawer"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        :color="$vuetify.theme.dark?'#121212':'#F0FBFAFF'"
        v-if="$vuetify.breakpoint.smAndDown"
    >
      <div style="margin-bottom: 200px;"></div>
      <v-divider></v-divider>
      <v-list
          nav
          dense
      >
        <div
            v-for="item in $store.state[isLoggedIn?'userHeader':'guestHeader']"
            :key="item.id"
        >
          <div
              v-if="item.children"
          >
            <v-list-group
                no-action
                :ripple="false"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title" style="font-weight: 600;"></v-list-item-title>
                </v-list-item-content>
              </template>
              <div v-for="child in item.children"
                   :key="child.id">
                <div v-if="child.children">
                  <v-list-group
                      no-action
                      sub-group
                      :ripple="false"
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title v-text="child.title"></v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                        v-for="sub in child.children"
                        :key="sub.id"
                        link
                        :to="sub.to||undefined"
                        :href="sub.link||undefined"
                        :target="sub.link?'_blank':undefined"
                        :ripple="false"
                        @click="performClick(sub.click)"
                    >
                      <v-list-item-title v-text="sub.title" style="font-weight: 600;"></v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                </div>
                <div v-else>
                  <v-list-item
                      link
                      class="pl-7"
                      :to="child.to||undefined"
                      :href="child.link||undefined"
                      :target="child.link?'_blank':undefined"
                      :ripple="false"
                      @click="performClick(child.click)"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="child.title" style="font-weight: 600;"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
            </v-list-group>
          </div>
          <div
              v-else
          >
            <v-list-item
                link
                :to="item.to||undefined"
                :href="item.link||undefined"
                :target="item.link?'_blank':undefined"
                :ripple="false"
                color="primary"
                @click="performClick(item.click)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item.title" style="font-weight: 600;"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>

      </v-list>
      <v-spacer></v-spacer>

      <template v-slot:append>
        <div class="pa-2 text-right">
          <v-btn
              v-for="icon in $store.state.social"
              :key="icon.name"
              icon
              :href="icon.link"
              target="_blank"
              class="mr-4"
          >
            <v-icon size="24px">
              mdi-{{ icon.name }}
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer
        v-model="rightDrawer"
        fixed
        temporary
        :color="$vuetify.theme.dark?'#121212':'#F0FBFAFF'"
        v-if="$vuetify.breakpoint.smAndDown"
        right
    >
      <div v-if="isLoggedIn" style="padding-top: 100px;" class="px-4 pb-2 text-center mb-4">
        <Profile :detailed="false"/>
      </div>
      <v-list
          nav dense rounded color="transparent"
      >
        <template
            v-for="item in $store.state.accessMenu">
          <div
              v-if="menuShow(item)"
              :key="item.id"
          >
            <div
                v-if="item.children"
            >
              <v-list-group
                  no-action
                  :ripple="false"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title" style="font-weight: 600;"></v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-for="child in item.children">
                  <div v-if="menuShow(child)"
                       :key="child.id">
                    <div v-if="child.children">
                      <v-list-group
                          no-action
                          sub-group
                          :ripple="false"
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title v-text="child.title"></v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <template
                            v-for="sub in child.children">
                          <v-list-item
                              :key="sub.id"
                              v-if="menuShow(sub)"
                              link
                              :to="sub.to||undefined"
                              :href="sub.link||undefined"
                              :target="sub.link?'_blank':undefined"
                              :ripple="false"
                              @click="clickItem(sub.click)"
                              color="primary"
                          >
                            <v-list-item-title v-text="sub.title" style="font-weight: 600;"></v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-list-group>
                    </div>
                    <div v-else>
                      <v-list-item
                          link
                          class="pl-7"
                          :to="child.to||undefined"
                          :href="child.link||undefined"
                          :target="child.link?'_blank':undefined"
                          :ripple="false"
                          @click="clickItem(child.click)"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="child.title" style="font-weight: 600;"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </div>
                </template>
              </v-list-group>
            </div>
            <div
                v-else
            >
              <v-list-item
                  link
                  :to="item.to||undefined"
                  :href="item.link||undefined"
                  :target="item.link?'_blank':undefined"
                  :ripple="false"
                  color="primary"
                  @click="clickItem(item.click)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.title" style="font-weight: 600;"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </template>

      </v-list>
      <v-spacer></v-spacer>

      <template v-slot:append>
        <div class="pa-2 text-right">
          <v-btn
              v-for="icon in $store.state.social"
              :key="icon.name"
              icon
              :href="icon.link"
              target="_blank"
              class="mr-4"
          >
            <v-icon size="24px">
              mdi-{{ icon.name }}
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <Dialog :dialog="customDialog"/>
  </div>
</template>

<script>
import Profile from "@/components/Profile";
import Dialog from "@/components/Dialog";
export default {
  name: "Header",
  components: {Dialog, Profile},
  data: ()=>({
    drawer: false,
    rightDrawer: false
  }),
  methods: {
    changeTheme(){
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('theme',this.$vuetify.theme.dark?'dark':'light');
    },
    performClick(click){
      if(typeof click !== 'undefined'){
        click();
      }
    }
  }
}
</script>

<style scoped>

</style>