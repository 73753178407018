<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col class="col-12 col-md-8 offset-md-2">
            <v-row :class="$vuetify.theme.dark?'':'black--text'">
              <v-col cols="12" v-if="!isLoggedIn">
                <v-row class="px-8 py-10 px-md-15 py-md-15">
                  <v-col cols="12">
                    <slide-y-up-transition appear :delay="300">
                      <h4 class="text-h5 text-h4" style="font-weight: 700;">Login</h4>
                    </slide-y-up-transition>
                    <slide-y-up-transition appear :delay="400">
                      <h6 class="text-subtitle-2 text-md-h6 mt-2 mt-md-4" style="font-weight: 600;">Enter your email and password to continue</h6>
                    </slide-y-up-transition>
                  </v-col>
                  <v-col cols="12">
                    <Login/>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-else>
                <v-row class="px-8 py-10 px-md-15 py-md-15">
                  <v-col cols="12">
                    <slide-y-up-transition appear :delay="300">
                      <h4 class="text-h5 text-h4" style="font-weight: 700;">Your Interests</h4>
                    </slide-y-up-transition>
                    <slide-y-up-transition appear :delay="400">
                      <h6 class="text-subtitle-2 text-md-h6 mt-2 mt-md-4" style="font-weight: 600;">Please select at least three items.
                        Your interests help us to recommend courses you might like.</h6>
                    </slide-y-up-transition>
                  </v-col>
                  <v-col cols="12" v-if="loadingInterests">
                    <v-row ref="interests" class="interests">
                      <v-col :cols="shuffleWidth()"
                           v-for="i in 30"
                           :key="i">
                        <slide-y-up-transition appear :delay="100 + (i*100)">
                          <v-skeleton-loader
                              style="width: 100% !important;"
                              type="heading"
                          ></v-skeleton-loader>
                        </slide-y-up-transition>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-else>
                      <v-chip-group
                          v-model="interests"
                          active-class="primary white--text"
                          column
                          multiple
                      >
                        <slide-y-up-transition appear v-for="(interest, i) in allInterests" :key="interest.id" :delay="100 + (i*100)">
                          <v-chip>{{ interest.name }}</v-chip>
                        </slide-y-up-transition>
                      </v-chip-group>
                    <slide-y-up-transition appear :delay="200">
                      <div class="text-center mt-4">
                        <v-btn class="px-15"
                               large
                               color="primary"
                               :disabled="interests.length < 3"
                               :loading="loading"
                               @click="updateData"
                        >Update</v-btn>
                      </div>
                    </slide-y-up-transition>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Dialog :dialog="customDialog"/>
  </v-app>
</template>

<script>
import { SlideYUpTransition, SlideYDownTransition } from 'vue2-transitions';
import Login from "@/components/Login";
import Dialog from "@/components/Dialog";
export default {
  name: "InterestScreen",
  components: {
    Dialog,
    Login,
    SlideYDownTransition,
    SlideYUpTransition
  },
  data: ()=>({
    interests: [],
    allInterests: [],
    customDialog: undefined,
    loadingInterests: false,
    widthRange: [100,50,70,80,120,90],
    loading: false
  }),
  created() {
    this.fetchContent();
  },
  mounted(){
    this.$refs.interests.querySelectorAll('.v-skeleton-loader__heading').forEach(node=>{
      node.style.width = '100%';
    });
  },
  methods: {
    shuffleWidth(){
      let width = [3,4,5,6,];
      let width1 = [100,50,70,80,120,90];
      return width.sort(function () {
        return Math.random() - 0.5;
      })[0];
    },
    fetchContent(){
      this.loadingInterests = true;
      this.loadContent('interests').then(data=>{
        if(data.status === 200){
          this.loadingInterests = false;
          this.allInterests = data.interests;
        }else{
          this.customDialog = {
            show: true,
            persistent: true,
            text: data.message,
            icon: 'error',
            buttons: [
              {
                text: 'Retry',
                color: 'orange',
                click: ()=>{
                  this.customDialog.show = false;
                  this.fetchContent();
                }
              },
              {
                text: 'Close',
                color: 'red',
                click: ()=>{
                  this.customDialog.show = false;
                }
              }
            ]
          };
        }
      }).catch(err=>{
        this.customDialog = {
          show: true,
          persistent: true,
          text: err.message,
          icon: 'error',
          buttons: [
            {
              text: 'Retry',
              color: 'orange',
              click: ()=>{
                this.customDialog.show = false;
                this.fetchContent();
              }
            },
            {
              text: 'Close',
              color: 'red',
              click: ()=>{
                this.customDialog.show = false;
              }
            }
          ]
        };
      });
  },
    updateData(){
      this.loading = true;
      let selected = [];
      this.allInterests.forEach((interest,i)=>{
        if(this.interests.includes(i)){
          selected.push(interest.id);
        }
      });
      fetch(this.$store.state.baseUrl+'user/interests',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.jwt
        },
        body: JSON.stringify({
          interests: selected
        })
      }).then(res=>{
        return res.json();
      }).then(data=>{
        this.loading = false;
        if(data.status === 200 || data.status === 201){

        }
        if(data.status === 401){
          return this.$router.push('/login?goto='+this.formatPath());
        }
        this.customDialog = {
          show: true,
          persistent: true,
          text: data.message,
          icon: data.status===200||data.status===201?'success':'error',
          closeBtn: {
            text: 'Close',
            color: 'red',
            click: ()=>{
              this.customDialog.show = false;
              if(data.status === 200){
                this.$store.commit('setInterests',data.interests);
                //this.fetchAuth(false);
              }
            }
          }
        };
      }).catch(err=>{
        this.loading = false;
        this.customDialog = {
          show: true,
          persistent: false,
          text: err.message,
          icon: 'error',
          buttons: [
            {
              text: 'Close',
              color: 'red',
              click: ()=>{
                this.customDialog.show = false;
              }
            },
            {
              text: 'Retry',
              color: 'orange',
              click: ()=>{
                this.updateData();
                this.customDialog.show = false;
              }
            }
          ]
        };
      });
    }
  },
  updated(){
    if(typeof this.$refs.interests !== 'undefined'){
      this.$refs.interests.querySelectorAll('.v-skeleton-loader__heading').forEach(node=>{
        node.style.width = '100%';
      });
    }
  }
}
</script>

<style>

</style>