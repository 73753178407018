import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeScreen from '../views/Screen/HomeScreen.vue'
import store from '../store'
import VueCookies from 'vue-cookies'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeScreen
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Screen/Auth/LoginScreen.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/Screen/Auth/SignUpScreen.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import('../views/Screen/Auth/ForgotPasswordScreen.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/Screen/Course/SearchScreen.vue')
  },
  {
    path: '/search/:query',
    name: 'querySearch',
    component: () => import('../views/Screen/Course/SearchScreen.vue')
  },
  {
    path: '/category/:slug',
    name: 'category',
    component: () => import('../views/Screen/Course/CategoryScreen.vue')
  },
  {
    path: '/course/:slug',
    name: 'course',
    component: () => import('../views/Screen/Course/SingleCourseScreen.vue')
  },
  {
    path: '/enrolments',
    name: 'enrolments',
    component: () => import('../views/Screen/Enrollment/EnrollmentScreen.vue')
  },
  {
    path: '/enrolments/:tab',
    name: 'enrolments-tab',
    component: () => import('../views/Screen/Enrollment/EnrollmentScreen.vue')
  },
  {
    path: '/enrolment/:slug',
    name: 'single-enrolment',
    component: () => import('../views/Screen/Enrollment/SingleEnrollmentScreen.vue')
  },
  {
    path: '/quiz/:slug',
    name: 'single-quiz',
    component: () => import('../views/Screen/Enrollment/SingleQuizScreen.vue')
  },
  {
    path: '/wishlist',
    name: 'wishlist',
    component: () => import('../views/Screen/User/WishlistScreen.vue')
  },
  {
    path: '/enrol/:slug',
    name: 'enrol-course',
    component: () => import('../views/Screen/Course/EnrollScreen.vue')
  },
  {
    path: '/project/rubric/:slug',
    name: 'project-rubric',
    component: () => import('../views/Screen/Enrollment/projectRubiric.vue')
  },
  {
    path: '/project/review/:slug',
    name: 'project-review',
    component: () => import('../views/Screen/Enrollment/projectReview.vue')
  },
  {
    path: '/admin/course/category',
    name: 'admin-course-category',
    component: () => import('../views/Screen/Admin/CourseCategory.vue')
  },
  {
    path: '/admin/course/interests',
    name: 'admin-course-interests',
    component: () => import('../views/Screen/Admin/Interest.vue')
  },
  {
    path: '/admin/courses',
    name: 'admin-courses',
    component: () => import('../views/Screen/Admin/Course.vue')
  },
  {
    path: '/admin/courses/:id',
    name: 'admin-courses-single',
    component: () => import('../views/Screen/Admin/Course.vue')
  },
  {
    path: '/admin/review/courses',
    name: 'admin-courses-review',
    component: () => import('../views/Screen/Admin/CourseReview.vue')
  },
  {
    path: '/admin/group-enrolments',
    name: 'admin-group-enrolments',
    component: () => import('../views/Screen/Admin/GroupEnrollment.vue')
  },
  {
    path: '/admin/project/submissions',
    name: 'admin-project-submissions',
    component: () => import('../views/Screen/Admin/ProjectSubmission.vue')
  },
  {
    path: '/admin/appeals',
    name: 'admin-appeals',
    component: () => import('../views/Screen/Admin/Appeal.vue')
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('../views/Screen/Admin/Users.vue')
  },
  {
    path: '/admin/users/:id',
    name: 'admin-users-id',
    component: () => import('../views/Screen/Admin/Users.vue')
  },
  {
    path: '/admin/roles',
    name: 'admin-roles',
    component: () => import('../views/Screen/Admin/Role.vue')
  },
  {
    path: '/admin/tutors/review',
    name: 'admin-tutor-review',
    component: () => import('../views/Screen/Admin/TutorReview.vue')
  },
  {
    path: '/admin/withdrawal',
    name: 'admin-withdrawal',
    component: () => import('../views/Screen/Admin/WithdrawRequest.vue')
  },
  {
    path: '/admin/transactions',
    name: 'admin-transactions',
    component: () => import('../views/Screen/Admin/Transaction.vue')
  },
  {
    path: '/admin/configuration',
    name: 'admin-configuration',
    component: () => import('../views/Screen/Admin/Configuration.vue')
  },
  {
    path: '/tutor/:slug',
    name: 'single-tutor',
    component: () => import('../views/Screen/Course/TutorScreen.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Screen/User/ProfilePage.vue')
  },
  {
    path: '/profile/:tab',
    name: 'profile-tab',
    component: () => import('../views/Screen/User/ProfilePage.vue')
  },
  {
    path: '/verify/:code',
    name: 'verify-certificate',
    component: () => import('../views/Screen/Course/Certificate.vue')
  },
  {
    path: '/activate/:ref/:email',
    name: 'activate-account',
    component: () => import('../views/Screen/User/Activate.vue')
  },
  {
    path: '/page/:slug',
    name: 'custom-page',
    component: () => import('../views/Screen/CustomPage.vue')
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import('../views/Screen/404Screen.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to,from,savedPosition){
    if(savedPosition){
      return savedPosition;
    }else if(to.hash){
      return  {
        el: to.hash,
        behavior: 'smooth'
      }
    }else{
      return {x:0,y:0}
    }
  }
})

router.afterEach((to, from) => {
  fetch(store.state.baseUrl+'pageview',{
    method: 'PUT',
    headers: {
      'Authorization': VueCookies.get('kbt')||'',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      url: to.fullPath,
      ref: from.fullPath
    })
  }).then(r=>{}).catch(e=>{});
})

export default router
