import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseUrl: process.env.VUE_APP_BACKEND_URL,
    url: process.env.VUE_APP_FRONTEND_URL,
    siteName: 'Kobbware Learn',
    showSplashScreen: false,
    showHeader: false,
    searchFilterData: {
      categoryChecked: [],
      recentlyUpdated: 'desc',
      ratingOrder: 'desc',
      ratingFilter: 0,
      priceOrder: 'desc'
    },
    showSearchFilter: false,
    drawerMenu: [],
    accessMenu: [
      {
        title: 'My Enrolments',
        to: '/enrolments',
        permissions: ['create:enrolments','edit:enrolments']
      },
      {
        title: 'Wishlist',
        to: '/wishlist',
        permissions: ['create:enrolments']
      },
      {
        title: 'Transaction History',
        to: '/profile/transactions',
      },
      {
        title: 'Course',
        permissions: ['create:course-categories,edit:course-categories',
          'delete:course-categories','create:courses,edit:courses',
          'delete:courses','create:group-enrolments,edit:group-enrolments',
          'delete:group-enrolments','create:course-interests,edit:course-interests',
          'delete:course-interests'
        ],
        children: [
          {
            title: 'All Categories',
            to: '/admin/course/category',
            permissions: ['create:course-categories,edit:course-categories','delete:course-categories']
          },
          {
            title: 'All Courses',
            to: '/admin/courses',
            permissions: ['create:courses,edit:courses','delete:courses']
          },
          {
            title: 'Reviews',
            to: '/admin/review/courses',
            permissions: ['review:courses']
          },
          {
            title: 'Group Enrolments',
            to: '/admin/group-enrolments',
            permissions: ['create:group-enrolments,edit:group-enrolments','delete:group-enrolments']
          },
          {
            title: 'Interests',
            to: '/admin/course/interests',
            permissions: ['create:course-interests,edit:course-interests','delete:course-interests']
          },
        ]
      },
      {
        title: 'Enrolments',
        permissions: ['create:courses,edit:courses','delete:courses'],
        children: [
          {
            title: 'Project Submissions',
            to: '/admin/project/submissions',
          },
          {
            title: 'Appeals',
            to: '/admin/appeals',
          },
        ]
      },
      {
        title: 'Admin',
        permissions: ['override-items','create:users,edit:users','edit:user-roles',
          'create:roles,edit:roles','delete:roles','review:tutors','review:withdraws',
          'get:all-transactions'
        ],
        children: [
          {
            title: 'Configuration',
            to: '/admin/configuration',
            permissions: ['override-items']
          },
          {
            title: 'Users',
            to: '/admin/users',
            permissions: ['create:users,edit:users','edit:user-roles']
          },
          {
            title: 'Roles',
            to: '/admin/roles',
            permissions: ['create:roles,edit:roles','delete:roles']
          },
          {
            title: 'Tutor Applications',
            to: '/admin/tutors/review',
            permissions: ['review:tutors']
          },
          {
            title: 'Withdrawal Requests',
            to: '/admin/withdrawal',
            permissions: ['review:withdraws']
          },
          {
            title: 'All Transaction History',
            to: '/admin/transactions',
            permissions: ['get:all-transactions']
          },
        ]
      },
      {
        title: 'Logout',
        click: `this.confirmLogout();`
      },
    ],
    guestHeader: [
      {
        title: 'Home',
        to: '/'
      },
      {
        title: 'Login',
        to: '/login'
      },
      {
        title: 'About Us',
        to: '/page/about-us'
      }
    ],
    userHeader: [
      {
        title: 'Home',
        to: '/'
      },
      {
        title: 'About Us',
        to: '/page/about-us'
      }
    ],
    social:[
      {
        name: 'phone',
        link: 'tel:+12345678'
      },
      {
        name: 'gmail',
        link: 'hello@learn.kobbware.com'
      },
      {
        name: 'instagram',
        link: 'https://instagram.com/'
      }
    ],
    contentLoaded: false,
    wishlists: [],
    user:{},
    jwt: '',
    userPermissions:[],
    loadingAuth: false,
    ckEditorConfig: {
      toolbar: {
        items: [
          'heading', '|',
          'bold', 'italic', '|',
          'outdent', 'indent', '|',
          'bulletedList', 'numberedList', '|',
          'blockQuote', 'undo', 'redo'
        ],
        shouldNotGroupWhenFull: true
      }
    },
    paystackKey: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY,
    disableRightSideBar: false,
    sessions: [],
  },
  getters: {
  },
  mutations: {
    setState: (state, payload)=>{
      state[payload.name] = payload.value;
    },
    setDisableRightSideBar: (state, val)=>{
      state.disableRightSideBar = val;
    },
    setShowSplashScreen: (state, val)=>{
      state.showSplashScreen = val;
    },
    setShowHeader: (state, val)=>{
      state.showHeader = val;
    },
    setSearchFilter: (state, val)=>{
      state.searchFilterData = val;
    },
    setShowSearchFilter: (state, val)=>{
      state.showSearchFilter = val;
    },
    setContentLoaded: (state, val)=>{
      state.contentLoaded = val;
    },
    addToWishlist: (state, id)=>{
      let existing = state.wishlists;
      if(existing.find(w=>w===id) == null){
        existing.push(id);
        state.wishlists = existing;
      }
      state.wishlists = state.wishlists.filter(w=>+w>0);
    },
    removeFromWishlist: (state, id)=>{
      let existing = state.wishlists;
      state.wishlists = existing.filter(w=>+w!==+id&&+w>0);
    },
    updateWishlist: (state, payload)=>{
      state.wishlists = payload;
    },
    toggleWishlist: (state, id)=>{
      let existing = state.wishlists;
      if(existing.find(w=>w===id) == null){
        existing.push(id);
        state.wishlists = existing;
        if(typeof state.user.name !== 'undefined'){
          return;
        }
        fetch(state.baseUrl + 'wishlist', {
          method: 'put',
          headers: {
            'Authorization': state.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ids: state.wishlists
          })
        }).then(r=>{
          return r.json();
        }).then(data=>{
          state.wishlists = data.wishlist.map(w=>{
            return w.courseId;
          });
        }).catch(err=>{

        });
      }else{
        state.wishlists = existing.filter(w=>w!==id);
        if(typeof state.user.name !== 'undefined'){
          return;
        }
        fetch(state.baseUrl + 'wishlist/'+id, {
          method: 'delete',
          headers: {
            'Authorization': state.jwt
          }
        }).then(r=>{
          return r.json();
        }).then(data=>{
          state.wishlists = data.wishlist.map(w=>{
            return w.courseId;
          });
        }).catch(err=>{

        });
      }
    },
    addToWallet: (state, amount)=>{
      state.user.wallet += +amount;
    },
    removeFromWallet: (state, amount)=>{
      state.user.wallet -= +amount;
    },
    setAuth: (state, payload)=>{
      state.jwt = payload.token;
      state.user = payload.user;
      state.userPermissions = payload.permissions;
    },
    setLoadingAuth: (state, val)=>{
      state.loadingAuth = val;
    },
    setDrawerMenu: (state, val)=>{
      state.drawerMenu = val;
    },
    setInterests: (state, val)=>{
      state.user.Interests = val;
    },
    updateWallet: (state, val)=>{
      state.user.wallet = +val;
    },
    updateAvatar: (state, val)=>{
      state.user.imageUrl = val;
    },
  },
  actions: {
  },
  modules: {
  }
})
