<template>
  <div>
    <v-overlay id="splash-bg" opacity="0" class="text-center" :class="$vuetify.theme.dark?'gradient-bg-dark':'gradient-bg'">
      <div class="splash-container">
        <img src="/img/kobbware-icon.png" class="splash-anim"/>
        <img :src="$vuetify.theme.dark?'/img/kobbware-elearn-white.png':'/img/kobbware-elearn.png'" id="kb-learn" class="splash-anim-2"/>
      </div>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "SplashScreen",
  watch:{
    '$vuetify.theme.dark'(val){
      if(val){
        document.getElementById('kb-learn').setAttribute('src','/img/kobbware-elearn-white.png');
      }else{
        document.getElementById('kb-learn').setAttribute('src','/img/kobbware-elearn.png');
      }
    }
  },
}
</script>

<style scoped>
  .splash-container{
    width: 100vw;
    height: 100vh;
  }
  .splash-anim,.splash-anim-2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    margin: 0;
  }
  .splash-anim{
    width: 65px;
    animation: move-left 4s ease forwards;
    z-index: 1;
  }
  .splash-anim-2{
    width: 200px;
    opacity: 0;
    z-index: 0;
    animation: move-right 4s ease forwards;
  }
  @keyframes move-left {
    0%{
      width: 65px;
    }
    12%{
      width: 100px;
    }
    24%{
      width: 50px;
    }
    35%{
      width: 65px;
    }
    50%{
      margin-left: 0;
    }
    100%{
      margin-left: -26%;
    }
  }
  @keyframes move-right {
    0%{

    }
    50%{
      margin-left: 0;
    }
    70%{
      opacity: 0;
    }
    100%{
      margin-left: 9%;
      opacity: 1;
    }
  }
  @media screen and (max-width: 320px){
    .splash-anim{
      width: 40px;
    }
    .splash-anim-2{
      width: 120px;
    }
    @keyframes move-left {
      0%{
        width: 40px;
      }
      12%{
        width: 60px;
      }
      24%{
        width: 30px;
      }
      35%{
        width: 40px;
      }
      50%{
        margin-left: 0;
      }
      100%{
        margin-left: -23%;
      }
    }
    @keyframes move-right {
      0%{

      }
      50%{
        margin-left: 0;
      }
      70%{
        opacity: 0;
      }
      100%{
        margin-left: 9%;
        opacity: 1;
      }
    }
  }
  @media screen and (max-width: 376px){
    .splash-anim{
      width: 50px;
    }
    .splash-anim-2{
      width: 150px;
    }
    @keyframes move-left {
      0%{
        width: 50px;
      }
      12%{
        width: 75px;
      }
      24%{
        width: 35px;
      }
      35%{
        width: 50px;
      }
      50%{
        margin-left: 0;
      }
      100%{
        margin-left: -21%;
      }
    }
    @keyframes move-right {
      0%{

      }
      50%{
        margin-left: 0;
      }
      70%{
        opacity: 0;
      }
      100%{
        margin-left: 9%;
        opacity: 1;
      }
    }
  }
  @media screen and (min-width: 460px){
    .splash-anim{
      width: 80px;
    }
    .splash-anim-2{
      width: 240px;
    }
    @keyframes move-left {
      0%{
        width: 80px;
      }
      12%{
        width: 120px;
      }
      24%{
        width: 60px;
      }
      35%{
        width: 80px;
      }
      50%{
        margin-left: 0;
      }
      100%{
        margin-left: -25%;
      }
    }
    @keyframes move-right {
      0%{

      }
      50%{
        margin-left: 0;
      }
      70%{
        opacity: 0;
      }
      100%{
        margin-left: 6%;
        opacity: 1;
      }
    }
  }
  @media screen and (min-width: 670px){
    .splash-anim{
      width: 80px;
    }
    .splash-anim-2{
      width: 240px;
    }
    @keyframes move-left {
      0%{
        width: 80px;
      }
      12%{
        width: 120px;
      }
      24%{
        width: 60px;
      }
      35%{
        width: 80px;
      }
      50%{
        margin-left: 0;
      }
      100%{
        margin-left: -17%;
      }
    }
    @keyframes move-right {
      0%{

      }
      50%{
        margin-left: 0;
      }
      70%{
        opacity: 0;
      }
      100%{
        margin-left: 6%;
        opacity: 1;
      }
    }
  }
  @media screen and (min-width: 767px){
    .splash-anim{
      width: 80px;
    }
    .splash-anim-2{
      width: 240px;
    }
    @keyframes move-left {
      0%{
        width: 80px;
      }
      12%{
        width: 120px;
      }
      24%{
        width: 60px;
      }
      35%{
        width: 80px;
      }
      50%{
        margin-left: 0;
      }
      100%{
        margin-left: -17%;
      }
    }
    @keyframes move-right {
      0%{

      }
      50%{
        margin-left: 0;
      }
      70%{
        opacity: 0;
      }
      100%{
        margin-left: 6%;
        opacity: 1;
      }
    }
  }
  @media screen and (min-width: 1024px){
    .splash-anim{
      width: 100px;
    }
    .splash-anim-2{
      width: 300px;
    }
    @keyframes move-left {
      0%{
        width: 100px;
      }
      12%{
        width: 150px;
      }
      24%{
        width: 75px;
      }
      35%{
        width: 100px;
      }
      50%{
        margin-left: 0;
      }
      100%{
        margin-left: -11%;
      }
    }
    @keyframes move-right {
      0%{

      }
      50%{
        margin-left: 0;
      }
      70%{
        opacity: 0;
      }
      100%{
        margin-left: 10%;
        opacity: 1;
      }
    }
  }
  @media screen and (min-width: 1200px){
    .splash-anim{
      width: 100px;
    }
    .splash-anim-2{
      width: 300px;
    }
    @keyframes move-left {
      0%{
        width: 100px;
      }
      12%{
        width: 150px;
      }
      24%{
        width: 75px;
      }
      35%{
        width: 100px;
      }
      50%{
        margin-left: 0;
      }
      100%{
        margin-left: -12%;
      }
    }
    @keyframes move-right {
      0%{

      }
      50%{
        margin-left: 0;
      }
      70%{
        opacity: 0;
      }
      100%{
        margin-left: 5%;
        opacity: 1;
      }
    }
  }
  @media screen and (min-width: 1440px){
    .splash-anim{
      width: 100px;
    }
    .splash-anim-2{
      width: 300px;
    }
    @keyframes move-left {
      0%{
        width: 100px;
      }
      12%{
        width: 150px;
      }
      24%{
        width: 75px;
      }
      35%{
        width: 100px;
      }
      50%{
        margin-left: 0;
      }
      100%{
        margin-left: -10%;
      }
    }
    @keyframes move-right {
      0%{

      }
      50%{
        margin-left: 0;
      }
      70%{
        opacity: 0;
      }
      100%{
        margin-left: 5%;
        opacity: 1;
      }
    }
  }
  @media screen and (min-width: 2560px){
    .splash-anim{
      width: 100px;
    }
    .splash-anim-2{
      width: 300px;
    }
    @keyframes move-left {
      0%{
        width: 100px;
      }
      12%{
        width: 150px;
      }
      24%{
        width: 75px;
      }
      35%{
        width: 100px;
      }
      50%{
        margin-left: 0;
      }
      100%{
        margin-left: -6%;
      }
    }
    @keyframes move-right {
      0%{

      }
      50%{
        margin-left: 0;
      }
      70%{
        opacity: 0;
      }
      100%{
        margin-left: 3%;
        opacity: 1;
      }
    }
  }
</style>